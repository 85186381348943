var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.filteredComments),function(comment){return _c('div',{key:comment.id,staticClass:"user-card text-break",class:{
      'card-m': _vm.isMobile(),
      'card card-hover': !_vm.isMobile(),
    }},[(_vm.isMobile() && _vm.hasPermission(comment.author))?_c('ControlModal',{attrs:{"modalID":'modal-menu' + comment.id},on:{"delete-event":function($event){return _vm.deleteComment(comment)},"edit-event":function($event){_vm.$set(comment, 'editing', true),
          _vm.$set(comment, 'editor', comment.content)}}}):_vm._e(),_c('b-overlay',{attrs:{"show":comment.requesting,"rounded":"","opacity":"0.6","spinner-small":"","variant":"dark","spinner-variant":"light"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('b-icon',{attrs:{"icon":"stopwatch","font-scale":"2","animation":"cylon"}}),_c('p',{attrs:{"id":"cancel-label"}},[_vm._v("Please wait...")])],1)]},proxy:true}],null,true)},[(!comment.editing)?_c('div',[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',[_c('router-link',{attrs:{"to":'/post/' + comment.post_id,"tag":"h5"}},[_vm._v(" "+_vm._s(comment.content)+" ")])],1),_c('b-col',{attrs:{"cols":"end"}},[_c('ControlButtons',{attrs:{"hasPermission":_vm.hasPermission(comment.author),"disabled":false,"compact":_vm.isMobile(),"modalID":'modal-menu' + comment.id},on:{"delete-event":function($event){return _vm.deleteComment(comment)},"edit-event":function($event){_vm.$set(comment, 'editing', true),
                  _vm.$set(comment, 'editor', comment.content)}}})],1)],1),_c('small',[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"Rating"}},[_c('b-icon',{attrs:{"icon":_vm.reactionIcon(comment.your_reaction),"color":_vm.reactionColor(comment.your_reaction)}}),_vm._v(_vm._s(comment.rating)+" ")],1),_c('time-ago',{attrs:{"datetime":comment.created,"tooltip":"right"}})],1)],1):_vm._e(),(_vm.hasPermission(comment.author) && comment.editing)?_c('b-row',[_c('b-col',[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [(comment.editing)?_c('b-button-group',{attrs:{"size":"sm","vertical":""}},[_c('b-button',{attrs:{"disabled":comment.editor == comment.content ||
                      !_vm.properEditorLength(comment.editor) ||
                      comment.requesting,"variant":"outline-success"},on:{"click":function($event){return _vm.updateComment(comment)}}},[_vm._v(" Save ")]),_c('b-button',{staticClass:"m-0",attrs:{"variant":"outline-danger","disabled":comment.requesting},on:{"click":function($event){comment.editing = false}}},[_vm._v("Cancel")])],1):_vm._e()]},proxy:true}],null,true)},[_c('b-form-textarea',{staticClass:"textarea",attrs:{"autofocus":"","keydown.enter.shift.exact":"newline","rows":"1","no-resize":"","disabled":comment.requesting,"max-rows":"5"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.updateComment(comment)}},model:{value:(comment.editor),callback:function ($$v) {_vm.$set(comment, "editor", $$v)},expression:"comment.editor"}})],1),(_vm.properEditorLength(comment.editor))?_c('small',[_vm._v(" "+_vm._s(_vm.editorLength(comment.editor))+"/"+_vm._s(_vm.maxCommentLength)+" ")]):_c('small',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(_vm.editorLength(comment.editor))+"/"+_vm._s(_vm.maxCommentLength)+" ")])],1)],1):_vm._e()],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }